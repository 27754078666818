<template>
  <div>

    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Period -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.period')"
              label-for="period"
            >
              <v-select
                id="period"
                class="select-size-sm"
                v-model="form.period"
                :options="LOV.period"
                :reduce="field => field.value"
                @input="handlePeriod"
              />
            </b-form-group>
          </b-col>

          <!-- Date Start -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.dateStart')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-start"
                :config="dateFormat"
                v-model="form.dateStart"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Date End -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.dateEnd')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-end"
                :config="dateFormat"
                v-model="form.dateEnd"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Transaction Type -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.masterDataCashbank.moduleName')"
              label-for="cashbank-code"
            >
              <!-- <v-select
                id="cashbank-code"
                class="select-size-sm"
                label="remark_eng"
                v-model="form.transactionTypeCode"
                :options="LOV.transactionType"
                :reduce="field => field.code"
                :clearable="false"
              /> -->
              <v-select
                v-if="companyActive.plan_id > 2"
                id="cashbank-account"
                class="select-size-sm"
                ref="firstFocusSelectInput"
                v-model="form.cashbankId"
                :options="LOV.cashbankCodeAccounts"
                :reduce="field => field.id"
                label="label"
              />
              <v-select
                v-else
                id="cashbank-account"
                class="select-size-sm"
                ref="firstFocusSelectInput"
                v-model="form.accountId"
                :options="LOV.cashbankAccounts"
                :reduce="field => field.id"
                label="label"
              />
            </b-form-group>
          </b-col>

          <!-- Transaction Type -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.transactionType')"
              label-for="transaction-type"
            >
              <v-select
                id="transaction-type"
                class="select-size-sm"
                label="remark_eng"
                v-model="form.transactionTypeCode"
                :options="LOV.transactionType"
                :reduce="field => field.code"
                :clearable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Tags -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.tags')"
              label-for="tags"
            >
              <v-select
                v-model="form.tags"
                id="tags"
                label="name"
                class="select-size-sm"
                taggable
                multiple
                :options="LOV.tags"
                :reduce="field => field.id"
                :create-option="field => ({id: Date.now(), name: field})"
              />
            </b-form-group>
          </b-col>

          <!-- Button Filter -->
          <b-col cols="12" md="3">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportCashBank.singular.cashBankReport') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

      <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        no-provider-filtering
        :filter-included-fields="columnToBeFilter"
        :fields="tableColumns"
        :items="itemLists"
        :filter="searchQuery"
        responsive
        primary-key="id"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <template #table-busy>
          <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <!-- Column: Code -->
        <template #cell(code)="{ item }">
          <span class="d-block text-nowrap">
            {{ item.code }}
            <br>
            <small class="font-italic text-primary">{{ item.transaction_type_name }}</small>
          </span>
        </template>

        <!-- Column: Reference Code-->
        <!-- <template #cell(reference_code)="{ item }">
          <span class="d-block text-nowrap">
            {{ (item.reference_code == 'false') ? '' : item.reference_code }}
          </span>
        </template> -->

        <!-- Column: Debit -->
        <template #cell(debit)="{ item }">
          <span class="text-right d-block text-nowrap">
            {{ formatCurrency(item.debit) }}
          </span>
        </template>

        <!-- Column: Credit -->
        <template #cell(credit)="{ item }">
          <span class="text-right d-block text-nowrap">
            {{ formatCurrency(item.credit) }}
          </span>
        </template>

        <!-- Column: Balance -->
        <template #cell(balance)="{ item }">
          <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', 'text-right', (item.balance > 0) ? 'text-success' : 'text-danger' ]">
          {{ (item.balance &lt; 0) ? `(${formatCurrency(item.balance * -1)})` : formatCurrency(item.balance) }}
        </span>
        </template>

      </b-table>

    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTable,
  BSpinner
} from 'bootstrap-vue'

import { format, getMonth, startOfMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'
import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency } from '@/utils/formatter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    BSpinner,
    vSelect,
    flatPickr
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const form = ref({
      period: 'thisMonth',
      dateStart: '',
      dateEnd: '',
      cashbankId: '',
      accountId: '',
      transactionTypeCode: '',
      tags: []
    })

    const periodReportText = ref()

    // export format
    const formats = ['xlsx', 'csv', 'txt']

    const dateFormat = ref({ dateFormat: 'd/m/Y' })

    const LOV = ref({
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ],
      transactionType: [
        { code: '', remark_eng: 'All' },
        { code: 'CR', remark_eng: 'Cash Advance Register' }
      ],
      cashbankAccounts: [],
      cashbankCodeAccounts: [],
    })

    // Table Handlers
    const tableColumns = [
      { key: 'date', label: 'Date' },
      { key: 'code', label: 'Code' },
      { key: 'remark', label: 'Description' },
      { key: 'debit', label: 'Debit', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'credit', label: 'Credit', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'balance', label: 'Balance', thClass: 'text-right', tdClass: 'text-right' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'date',
      'reference_code'
    ]

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const firstMonthOfYear = subMonths(thisMonth, whatMonth - 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const firstMonthOfPreviousYear = subYears(firstMonthOfYear, 1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateStart = format(startOfMonth(previousMonth), formatString)
        form.value.dateEnd = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateStart = format(startOfMonth(firstMonthOfYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateStart = format(startOfMonth(firstMonthOfPreviousYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      periodReportText.value = `${formatStartDate} to ${formatEndDate}`
    }

    const companyActive = storestate.state.user.currentCompany

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: '' })

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const getTransactionTypes = async () => {
      const transactionTypes = await get({ url: 'value/transaction-type?module=CASHBANK'})
      transactionTypes.map((transactionType) => {
        LOV.value.transactionType.push({ code: transactionType.code, remark_eng: transactionType.remark_eng })
      })
    }

    const getCashbankAccount = async () => {
      LOV.value.cashbankAccounts = await get({ url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    }

    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
    }

    onMounted(async () => {
      getCashbankAccount()
      await getTags()
      await getTransactionTypes()
      handlePeriod()

      if (companyActive.plan_id > 2) {
        getCashbankCode()
      }

      const params = [
        `dateStart=${form.value.dateStart}`,
        `dateEnd=${form.value.dateEnd}`,
        `transactionTypeCode=${form.value.transactionTypeCode ?? ''}`,
        `tags={${form.value.tags}}`,
        `accountId=${form.value.accountId}`,
        `cashbankId=${form.value.cashbankId}`
      ]

      const url = `reports/cashbank?${ params.join('&') }`
      await fetchLists(url)
      updatePeriodText()
    })

    return {
      form,
      periodReportText,
      formats,
      LOV,
      dateFormat,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      companyActive,
      fetchLists,
      handlePeriod,
      updatePeriodText,
      formatCurrency
    }
  },
  methods: {
    getReport () {
      const params = [
        `dateStart=${this.form.dateStart}`,
        `dateEnd=${this.form.dateEnd}`,
        `transactionTypeCode=${this.form.transactionTypeCode ?? ''}`,
        `tags={${this.form.tags}}`,
        `accountId=${this.form.accountId}`,
        `cashbankId=${this.form.cashbankId}`
      ]

      const url = `reports/cashbank?${ params.join('&') }`
      this.fetchLists(url)
      this.updatePeriodText()
    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCashBank.singular.cashBankReport'), '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCashBank.singular.cashBankReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
